<template>
  <v-container fluid>

    <div class="row row--dense">
      <v-col cols="12" xl="12" lg="12">
        <div class="d-flex justify-space-between " >
          <div class="d-flex bordered qp-tab-nav">
            <div class="nv_item">
              <v-autocomplete
                  :items="[]"
                  item-value="venue_service_id"
                  item-text="name"
                  outlined
                  multiple
                  placeholder="Services"
                  background-color="rgba(79, 174, 175, 0.1)"
                  hide-details
                  style="width: 150px;"
                  class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                  :height="46"
                  dense
                  color="#4FAEAF"
                  item-color="#4FAEAF"
                  disabled
              >
              </v-autocomplete>
            </div>
            <MembershipTopTab :dashboardClass="false" :scheduleClass="false" :scheduleNewClass="false" :analyticsClass="true" :membersClass="false"/>
          </div>
        </div>
      </v-col>
    </div>
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <v-row>
      <membership-analytics
          :params="params"
          :showProductType="false"
          stackType="all"
          :exportPermission="
          checkExportPermission($modules.memberships.dashboard.slug)
        "
      ></membership-analytics>
    </v-row>
  </v-container>
</template>

<script>
import MembershipAnalytics from "@/components/Chart/MembershipAnalyticsGraph.vue";
import MembershipTopTab from "./MembershipTabs/MembershipTopTab";
export default {
  components: {
    MembershipAnalytics,
    MembershipTopTab,
  },
  data() {
    return {
      btnShow: false,
      params: {  },
    };
  },

  methods:{
    gotoPage(route){
      this.$router.push(route);
    },
  },
  mounted() {
    setTimeout(() => {
      this.btnShow = true;
    }, 10);
  },
};
</script>

<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}
.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}
.personal-trainer_ic {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  background-position: center center;
  padding: 10px;
  background-size: contain;
}

.light-blue-text{
  color: rgba(17, 42, 70, 0.8) !important;
}
</style>
